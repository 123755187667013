import Link from 'next/link'
import React, { useEffect } from 'react'
import { NavigationMenu, Button, hooksHelpers } from 'day8-ui'
import { IoMdArrowForward } from "@react-icons/all-files/io/IoMdArrowForward";
import { RxCross1 } from "@react-icons/all-files/rx/RxCross1";
import Cookies from 'js-cookie';

import TywMiniLogo from '../svg/TywMiniLogo'
import NavigationMenuContent from './NavigationMenuContent'
import NavbarUser from './NavbarUser'
import MobileMenu from './MobileMenu';
import { WpImageType } from '../../globalTypes'
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const { useHideOnScrollDown } = hooksHelpers
export type NavigationMenuContentDataItem = {
  title: string
  link?: string
  buttonText: string
  description: string
  image?: {
    url: string
    alt?: string
  }
  additionalLinksTitle?: string
  additionalLinks?: {
    title: string
    link: string
  }[]
}
type NavbarProps = {
  currencyHidden?: boolean
  isNavbarTransparent?: boolean
  isNavbarFixed?: boolean
  navbarConfig: NavbarConfig
  globalConfig: GlobalConfig
}

export type GlobalConfig = {
  countdown_banner: string
  campaign_banner: string
}
export type NavbarConfig = {
  label: string
  href?: string
  content?: {
    buttonText: string
    title: string
    description: string
    link?: string
    additionalLinksTitle: string
    additionalLinks: {
      title: string
      link: string
    }[]
    image: WpImageType
  }[]
}[]
export default function Navbar({ currencyHidden, isNavbarTransparent, isNavbarFixed, navbarConfig, globalConfig, ...props }: NavbarProps) {
  const [showBanner, setShowBanner] = React.useState(false);
  const isFunnelYachtsRedirectFeature = useFeatureIsOn("custom-yachts-redirect");
  const redirectUrl = isFunnelYachtsRedirectFeature ? '/browse/yachts' : '/browse';
  const isVisible = useHideOnScrollDown();
  useEffect(() => {
    setShowBanner(globalConfig && !!globalConfig.campaign_banner && Cookies.get('campaign_banner') === undefined)
  }, [globalConfig.campaign_banner])
  const handleHideBanner = () => {
    Cookies.set('campaign_banner', 'true', { expires: 1 })
    setShowBanner(false)
  };
  return (
    <div className={`dui-sticky dui-top-0 dui-bg-white dui-z-10 dui-transition ${isVisible ? "" : "dui-translate-y-[-100%]"}`}>
      <div className='dui-flex dui-justify-between dui-items-center dui-gap-4 dui-container dui-mx-auto dui-py-2'>
        <div className=''>
          <Link href='/'>
            <TywMiniLogo className="dui-text-black dui-w-[64px] dui-h-[64px]" fill="var(--dui-color-black)" />
          </Link>
        </div>
        <div className='dui-hidden lg:dui-block dui-flex-grow dui-w-[100%]'>
          <div>
            <NavigationMenu
              items={navbarConfig?.map(({ label, content }) => ({ label, content: content && <NavigationMenuContent data={content} sectionTitle={label} /> }))}
            />
          </div>
        </div>
        <div className='dui-shrink-0'>
          <div className='dui-hidden lg:dui-block'>
            <NavbarUser />
          </div>
          <div className='lg:dui-hidden'>
            <MobileMenu navbarConfig={navbarConfig} />
          </div>
        </div>
      </div>
      {showBanner && globalConfig.campaign_banner && (
        <a href={redirectUrl} className='dui-block dui-w-full dui-bg-primary-950 dui-p-4 dui-font-heading dui-text-white dui-text-center dui-uppercase' onClick={handleHideBanner}>
          <div className='dui-container dui-mx-auto  dui-flex dui-items-center dui-gap-4 dui-justify-center'>
            <div className=''>
              <p className="dui-inline-block dui-text-sm dui-font-body dui-text-white dui-font-bold dui-underline dui-underline-offset-2">{globalConfig.campaign_banner}</p>
            </div>
          </div>
        </a>
      )}
    </div>
  )
}